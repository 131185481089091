@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	:focus-visible {
		@apply z-50;
		@apply outline-4;
		@apply outline-secondary;
		@apply outline-offset-4;
		box-shadow: 0 0 0 4px #065777;
	}

	.focus-visible {
		@apply z-50;
		@apply outline-[3px];
		@apply outline-secondary;
		@apply outline-offset-[3px];
		box-shadow: 0 0 0 3px #065777;
	}
}

.grecaptcha-badge {
	visibility: hidden;
}

@media (min-aspect-ratio: 16/9) {
	.video-bg {
		/* height = 100 * (9 / 16) = 56.25 */
		height: 56.25vw;
	}
}

@media (max-aspect-ratio: 16/9) {
	.video-bg {
		/* width = 100 / (9 / 16) = 177.777777 */
		width: 177.78vh;
	}
}

input[type="search"] {
	-webkit-appearance: none;
	-webkit-border-radius: 3px;
	border-radius: 3px;
}

@keyframes loopScroll {
	from {transform: translateX(0);}
	to {transform: translateX(-50%);}
}

.translate-3d {
	transform: translate3d(0, 0, 0);
}
